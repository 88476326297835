import React, { useState } from 'react';
import { makeStyles } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import en from '../lang/en/port.json';
import th from '../lang/th/port.json';

import Child from '../component/portChild'
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grow from '@mui/material/Grow';
import axios from 'axios';
import { Skeleton } from '@mui/material';

import moment from 'moment'
import 'moment/locale/th'  // without this line it didn't work
import {
  setLang, setDarkMode, setPage
} from '../redux/action';
import { connect } from 'react-redux';


// const useStyles = makeStyles((theme) => ({
//     root: {
//       flexGrow: 1,
//     },
//     loader: {
//       padding: theme.spacing(2),
//       textAlign: 'center',
//     },
//     paper: {
//       padding: theme.spacing(2),
//       textAlign: 'center',
//       color: theme.palette.text.secondary,
//     },
//   }));

const Skill = ({setPage, dark, CurrentLang, endpoint}) => {
const [data, setData] = React.useState(null)

  React.useEffect(() => {
    setPage(CurrentLang == 'th' ? th.title : en.title)

    axios({
      method: 'get',
      url: endpoint.web + '/myport/glproject',
    }).then(function (response) {
      if (response.data.status == 200) {
        const hold = window.atob(response.data.res).replace(/<\/?[^>]+>/gi, '');
        setData(JSON.parse(hold))
        console.log(JSON.parse(hold))
      }
  })
  .catch(function (e) {
      // handle error
      console.log('err',e)
  });

  }, [])
    const [Lang, setLang] = useState(th);

    React.useEffect(() => {
      if (CurrentLang === 'th') {
        setLang(th);
      } else {
        setLang(en);
      }
    }, [CurrentLang]);

    //const classes = useStyles();
    return (
        <div className='pl-3 pr-3'>
          <Slide direction="right" in={true} timeout={localStorage.getItem('graphic') === null ? 600 : 0}>
            <Typography variant="h5" className={dark ? 'text-light' : ''}>{Lang.title}</Typography>
          </Slide>
            <hr className={dark ? 'border-light' : ''}/>
            <Grid container spacing={3}>
                {Lang.list.map((item, i) => (
                    <Child key={i + 1} item={item} i={i} Lang={Lang} dark={dark} />
                ))}
            </Grid>
            <Slide sx={{marginTop:'50px'}} direction="right" in={true} timeout={localStorage.getItem('graphic') === null ? 600 : 0}>
            <Typography variant="h5" className={dark ? 'text-light' : ''}>{Lang.gltitle}</Typography>
          </Slide>
            <hr className={dark ? 'border-light' : ''}/>
             <Grid container spacing={3}>
                {data != null ? data.map((item, i) => (
                     <Grid item xs={12} sm={6} key={item.id}>
                     <Paper>
                             <Card sx={{backgroundColor: dark ? '#011345' : ''}}>
                                 <CardActionArea>
                                 <CardContent>
                                     <Grow in={true} timeout={localStorage.getItem('graphic') === null ? 1500 : 0}>
                                     <Typography gutterBottom variant="h5" component="h2" className={dark ? 'text-light' : ''}>
                                         {item.name}
                                     </Typography>
                                     </Grow>
                                     <Grow in={true} timeout={localStorage.getItem('graphic') === null ? 1600 : 0}>
                                     <Typography variant="subtitle1" color="textSecondary" component="p" className={dark ? 'text-dark-secondary' : ''}>
                                         {item.topics.join(", ")}
                                     </Typography>
                                     </Grow>
                                     <hr className={dark ? 'border-light' : ''} />
                                     <Grow in={true} timeout={localStorage.getItem('graphic') === null ? 1600 : 0}>
                                     <Typography variant="subtitle2" color="textSecondary" className={dark ? 'text-dark-secondary' : ''}>
                                         <i>{Lang.glupdate} {moment.utc(item.last_activity_at).lang(CurrentLang).local().format('DD MMMM YYYY HH:mm')}</i>
                                     </Typography>
                                     </Grow>
                                     <Grow in={true} sx={{marginTop:'20px'}} timeout={localStorage.getItem('graphic') === null ? 1900 : 0}>
                                     <Typography variant="body1" component="p" className={dark ? 'text-light' : ''}>
                                         {item.description}
                                     </Typography>
                                     </Grow>
                                 </CardContent>
                                 </CardActionArea>
                                 {item.web_url !== '' && (
                                 <Grow in={true} timeout={localStorage.getItem('graphic') === null ? 2100 : 0}>
                                 <CardActions>
                                     <Button href={item.web_url} target="_blank" sx={{color: dark ? '#44ad67' : '#648cfa'}} size="small">{Lang.see}</Button>
                                 </CardActions>
                                 </Grow>
                                 )}
                             </Card>
                         </Paper>
                 </Grid>
                )) : (
                  <>
                  <Grid item xs={12} sm={6}>
                     <Paper>
                             <Card sx={{backgroundColor: dark ? '#011345' : ''}}>
                                 <CardActionArea>
                                 <CardContent>
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '30px' }} />
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '15px' }} />
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '10px' }} />
                                 <hr className={dark ? 'border-light' : ''} />
                                     <>
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     </>
                                 </CardContent>
                                 </CardActionArea>
                             </Card>
                         </Paper>
                 </Grid>
                 <Grid item xs={12} sm={6}>
                     <Paper>
                             <Card sx={{backgroundColor: dark ? '#011345' : ''}}>
                                 <CardActionArea>
                                 <CardContent>
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '30px' }} />
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '15px' }} />
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '10px' }} />
                                 <hr className={dark ? 'border-light' : ''} />
                                     <>
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     </>
                                 </CardContent>
                                 </CardActionArea>
                             </Card>
                         </Paper>
                 </Grid>
                 <Grid item xs={12} sm={6}>
                     <Paper>
                             <Card sx={{backgroundColor: dark ? '#011345' : ''}}>
                                 <CardActionArea>
                                 <CardContent>
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '30px' }} />
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '15px' }} />
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '10px' }} />
                                 <hr className={dark ? 'border-light' : ''} />
                                     <>
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     </>
                                 </CardContent>
                                 </CardActionArea>
                             </Card>
                         </Paper>
                 </Grid>
                 <Grid item xs={12} sm={6}>
                     <Paper>
                             <Card sx={{backgroundColor: dark ? '#011345' : ''}}>
                                 <CardActionArea>
                                 <CardContent>
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '30px' }} />
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '15px' }} />
                                 <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '10px' }} />
                                 <hr className={dark ? 'border-light' : ''} />
                                     <>
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     <Skeleton className={dark ? 'bg-secondary' : ''} variant="text" sx={{ fontSize: '12px' }} />
                                     </>
                                 </CardContent>
                                 </CardActionArea>
                             </Card>
                         </Paper>
                 </Grid>
                  </>
                )}
            </Grid>
        </div>
     );
}
 
const mapStateToProps = (state) => ({
  dark: state.dark,
  CurrentLang: state.CurrentLang,
  currentPage: state.currentPage,
  endpoint: state.endpoint,
});
const mapDispatchToProps = (dispatch) => ({
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(Skill);