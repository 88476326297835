import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  Zoom,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import DialogTitle from '@mui/material/DialogTitle';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const FavCom = ({ arr, setOpen, Lang, dark }) => {
  const [loaded, setLoaded] = React.useState(false)
  const handleImageLoad = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 300);
  }
  return (
    <div style={{ backgroundColor: dark ? '#011345' : '' }}>
      <Slide direction='left' in={true} timeout={localStorage.getItem('graphic') === null ? 900 : 0}>
        {arr.link !== undefined && arr.link !== '' ? (
          <a href={arr.link} target="_blank" rel="noopener noreferrer">
            <DialogTitle id="scroll-dialog-title" className={dark ? 'text-light' : ''}>
              {arr.title}
            </DialogTitle>
          </a>
        ) : (
          <DialogTitle id="scroll-dialog-title">
            {arr.title}
          </DialogTitle>
        )}
      </Slide>
      <DialogContent dividers className={dark ? 'border-light' : ''}>
        <br />
        <Grid container spacing={4}>
          <Grid item xl md={6} data-aos="fade-in" data-aos-duration="1300">
            <img
              src={arr.src}
              alt={arr.title}
              width={'100%'}
              style={{ display: loaded == false ? 'none' : 'initial' }}
              onLoad={handleImageLoad}
            />
            <div style={{ display: loaded == false ? 'initial' : 'none' }}>
              <Skeleton variant="rect" sx={{width : {md: 400, xs :'70vw'}}} height={220} />
            </div>

          </Grid>
          <Grid item sm md={6}>
            <Grow in={true} timeout={localStorage.getItem('graphic') === null ? 800 : 0}>
              <Box mt={3}>
                <Typography className={dark ? ' text-dark-secondary' : 'text-muted'}>
                  {arr.desc}
                </Typography>
              </Box>
            </Grow>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} sx={{ color: dark ? '#44ad67' : '#648cfa' }}>
          {Lang.ok}
        </Button>
      </DialogActions>
    </div>
  );
}

export default FavCom;